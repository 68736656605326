import React, {Component} from 'react';
import  './AboutPage.css';
import Contact from '../Contact/Contact';
import { Helmet } from 'react-helmet';
import {Link} from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import WorkHeader from '../WorkHeader/WorkHeader';

class AboutPage extends Component {
  
  componentDidMount(){
    this.props.checkHeaderSolid(true);
  }

  componentWillUnmount(){
    this.props.checkHeaderSolid(false);
  }

  render() {
    
    let year = new Date().getFullYear();
    let yearsExperience = year - 2011;
    let title = "About Jumpoff";

    return <div className="about-page single-page">
      <WorkHeader title="About Jumpoff" bgImage={false} overlayColor={'rgba(20,20,20,.4)'} />
      <section>
      
      <Helmet>
        <title>JumpOff - {title}</title>
        <meta name="title" content={title} />
        <meta propety="og:title" content={title} />
        <meta name="description" content="Jumpoff is a company focused on designing and developing great experiences for the web." />
        <meta property="og:description" content="Jumpoff is a company focused on designing and developing great experiences for the web." />
        <meta name="url" content={window.location.href} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="jo-row">
        <div className="jo-content jo-post-content-wrapper">
          <h2>Short Story</h2>
          <p>Jumpoff was a company operating between 2015-2020, which focused on designing and developing <Link to="/portfolio">great experiences for the web</Link>.</p>
        </div>
      </div>
      <div id="contact" className="jo-row">
        <div className="jo-content">
          <Contact name="aboutpageform"/>
        </div>  
      </div>
    </section>
    </div>
  }

}

export default AboutPage;

